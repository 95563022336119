<template>
  <div>
    <div class="header query-wrap">
      <div class="query">
        <div class="menu item" v-if="activeIndex == '1'">
          <span class="font_14">运行状态：</span>
          <el-button
            :key="index"
            v-for="(e, index) in boxOption"
            @click="handleSelect(index, 'boxStatus')"
            :class="boxStatusNote == index ? 'primary' : ''"
            >{{ e }}</el-button
          >
        </div>
        <div class="item session1" v-if="activeIndex == '1'">
          <div class="shopBox ml20" ref="shopBox" @click="treeClick">
            <i>组织：</i>
            <input type="text" readonly name="" id="" :value="checkedName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox" v-if="activeIndex == '1'" ref="treeBox" v-show="treeIsShow">
            <el-input
              placeholder="请输入组织名称搜索"
              v-model="searchOrg"
              @change="searchOrg"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="orgcode"
              :data="shopsTree"
              :props="defaultProps"
              ref="tree"
              :default-expanded-keys="[orgcode]"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
            <div class="dialogBtn pt20 mb20 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
        <div class="item">
          <el-input
            placeholder="请输入产品ID、MacID、端口号进行检索"
            v-model="keyword"
            class="input-with-select searchInput"
            @change="searchByKeywords()"
          >
            <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="searchByKeywords()"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="operation clearfix">
      <!-- <el-button @click="handleAssign" class="primary">红外批量分配</el-button> -->
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
        <button class="ag-grey-button bord ml10" v-on:click="setTrue()">
          <i class="fa fa-gear thd"></i>
        </button>
        <div id="custom-column-wrap" class="ag-custom-column bor fts" v-show="dialogIsShow">
          <div class="ag-layout-block">
            <span style="font-weight: bold">设置自定义列</span>
            <a href="javascript:;" @click="setDefult" id="reset-column" class="fr">恢复默认</a>
          </div>
          <div class="ag-layout-block hori ag-check-group">
            <template>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <div style="margin: 5px 0"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox
                  v-for="item in copyCheckedData"
                  :label="item.key"
                  :key="item.key"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-checkbox
                >
              </el-checkbox-group>
            </template>
          </div>
          <div class="ag-layout-block top">
            <button @click="getCheckTrue" type="button" class="ag-model-btn blue fts mr5">保存</button>
            <button @click="dialogCancel" type="button" class="ag-model-btn gray fts">取消</button>
          </div>
        </div>
      </div>
    </div>
    <div class="integratable">
      <el-table v-loading="isFetching" :data="tableData" @sort-change="sortChange" style="width: 100%" row-key="pkid">
        <el-table-column
          v-for="(item, index) in checkedData"
          :min-width="item.width"
          :key="index"
          :label="item.label"
          :prop="item.key"
          :sortable="item.sortable"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.key === 'boxNo'">
              {{ isEmpty(scope.row.boxNo) ? '--' : scope.row.boxNo }}
            </div>
            <template v-else-if="item.key === 'status'">
              <status :data="scope.row.status" />
            </template>
            <div v-else-if="item.key === 'shopName'">
              <span v-if="isEmpty(scope.row.shopName)">--</span>
              <el-tooltip v-else class="item" effect="dark" :content="scope.row.shopName" placement="top">
                <span>{{ scope.row.shopName?.slice(0, 10) }}{{ scope.row.shopName?.length > 10 ? '...' : '' }}</span>
              </el-tooltip>
            </div>
            <div v-else-if="item.key === 'vendorTxts'">
              <span type="text" size="small">{{ scope.row.vendorTxts && scope.row.vendorTxts.join('、') }}</span>
            </div>
            <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="restFirst(scope.row)" type="text" size="small">门店分配</el-button>
            <el-button @click="restSecond(scope.row)" type="text" size="small">设备管理</el-button>
            <el-button type="text" size="small" @click="toDeviceInfo(scope.row)">设备信息查询</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageOptions.pageNumber"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" custom-class="addDialog" :visible.sync="dialogFormVisible" :before-close="cancelSetpsd">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="盒子编号：" :label-width="formLabelWidth">
          <span>{{ form.boxNo }}</span>
        </el-form-item>
        <el-form-item label="MAC-ID：" :label-width="formLabelWidth">
          <span>{{ form.macId }}</span>
        </el-form-item>
        <el-form-item label="盒子访问用户名：" :label-width="formLabelWidth" prop="currUser">
          <el-input v-model="form.currUser" autocomplete="off" placeholder="请输入" maxlength="64"></el-input>
        </el-form-item>
        <el-form-item label="密码：" :label-width="formLabelWidth" prop="currPass">
          <div class="pswbox">
            <el-input
              v-model="form.currPass"
              autocomplete="off"
              placeholder="请输入"
              :type="password"
              maxlength="30"
            ></el-input>
            <i
              v-if="eyeshow"
              class="password-icon iconfont icon-icon-mimabubukejian"
              @click="
                eyeshow = !eyeshow
                password = 'text'
              "
            ></i>
            <i
              v-if="!eyeshow"
              class="password-icon iconfont icon-icon-mimakejian"
              @click="
                eyeshow = !eyeshow
                password = 'password'
              "
            ></i>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSetpsd">取 消</el-button>
        <el-button type="primary" @click="updateAgentCurrentAuth('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
    <!-- 批量分配 -->
    <el-dialog title="批量分配" :visible.sync="dialogAssignVisible" class="dialogAssignVisible">
      <div class="upload flex alignCenter item">
        <span>导入文件：</span>
        <div class="elDiv">
          <el-input
            readonly
            placeholder="点击浏览按钮导入文件"
            v-model="uploadFileName"
            type="text"
            name="file"
            style="width: 300px"
          />
        </div>
        <el-upload
          class="upload-demo"
          ref="upload"
          accept="application/vnd.ms-excel"
          :action="uploadUrl"
          :headers="headerData"
          with-credentials="true"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :on-change="handleChange"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" style="margin-left: 10px">浏览</el-button>
        </el-upload>
      </div>
      <p v-show="fileEmpty" class="errorShow mt5">文件不能为空！</p>
      <div slot="footer" class="assignDialog-footer flex alignCenter flex-between">
        <a :href="downloadTempUrl">下载模板</a>
        <div class="dialog-button">
          <el-button @click="cancelAssign">取消</el-button>
          <el-button type="primary" @click="submitUpload">提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogResultVisible" width="30%" @close="handleUploadResult">
      <div class="content">
        <p style="font-size: 14px">
          分配完成，<span style="color: #14bd14">{{ successNum }}</span
          >条分配成功，<span style="color: #f00">{{ errorList.length }}</span
          >条分配失败<span v-show="errorList.length > 0">，失败数据请检查后再次上传。</span>
        </p>
        <div class="errorContent" v-show="errorList.length > 0">
          <p style="font-size: 14px; margin-top: 10px">错误详情:</p>
          <p v-for="(item, index) in errorList" :key="index" style="font-size: 14px">
            行数{{ item.rowNum }},{{ item.messageList.join() }};
          </p>
        </div>
        <div class="errorContent" v-show="warnMsgList.length > 0">
          <p style="font-size: 14px; margin-top: 10px">警告信息:</p>
          <p v-for="(item, index) in warnMsgList" :key="index" style="font-size: 14px">
            行数{{ item.rowNum }},{{ item.messageList.join() }};
          </p>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="handleUploadResult">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogImportErrVisible" width="30%" @close="handleErrorReason">
      <p style="font-size: 14px">{{ importErrorReason }}</p>
      <div slot="footer">
        <el-button type="default" @click="handleErrorReason">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import tmpColumn from 'src/mixins/templateColumn'
import { upgrade, updateAgentCurrentAuth } from 'src/api/legacy'
import { getNewestToken } from 'src/api/legacy'
import { getProductList } from 'src/api/product'
import { httpUrl, storeHttp, storeHttpV2, formatLabel, generateTableData } from 'src/utils/common'
import { isEmpty } from 'src/utils/method.js'
import { runningStatusList } from 'src/utils/dict'
import Status from 'src/components/Status'

export default {
  components: {
    Status,
  },
  mixins: [treemixin, tmpColumn],
  data() {
    return {
      runningStatusList: [
        {
          label: '全部',
          value: null,
        },
      ].concat(runningStatusList),
      checkedData: [
        {
          label: '盒子编号',
          key: 'boxNo',
          width: 120,
          sortable: true,
        },
        {
          label: '产品ID',
          key: 'productId',
          width: 120,
          sortable: true,
        },
        {
          label: '运行状态',
          key: 'status',
          width: 120,
          sortable: true,
        },
        {
          label: '盒子ID',
          key: 'boxId',
          width: 100,
          sortable: true,
        },
        {
          label: '盒子MacID',
          key: 'macId',
          width: 120,
          sortable: true,
          disabled: true,
        },
        {
          label: '端口号',
          key: 'boxPort',
          width: 80,
          sortable: true,
        },
        {
          label: '盒子类型',
          key: 'boxTypeStr',
          width: 100,
          sortable: true,
        },
        {
          label: '产品版本',
          key: 'productVersion',
          width: 100,
          sortable: true,
        },
        {
          label: '分配状态',
          key: 'toshop',
          width: 100,
          sortable: true,
        },
        {
          label: '分配日期',
          key: 'toshopTime',
          width: 130,
          sortable: true,
        },
        {
          label: '自动绑定摄像头',
          key: 'autoBind',
          width: 120,
          sortable: true,
        },
        {
          label: '客户名称',
          key: 'cname',
          width: 100,
          sortable: true,
        },
        {
          label: '门店名称',
          key: 'shopName',
          width: 120,
          sortable: true,
        },
        {
          label: '管理摄像头品牌',
          key: 'vendorTxts',
          width: 120,
          sortable: true,
        },
        {
          label: '入库日期',
          key: 'createtime',
          width: 130,
          sortable: true,
        },
      ],
      copyCheckedData: [],
      checkedCities: [
        'productId',
        'status',
        'boxNo',
        'macId',
        'boxTypeStr',
        'boxPort',
        'productVersion',
        'toshop',
        'cname',
        'shopName',
        'operation',
      ], //默 认列 cameraCount
      disabledCheck: [
        '盒子编号',
        'MAC-ID',
        '盒子类型',
        '端口号',
        '入库日期',
        '分配状态',
        '客户名称',
        '门店名称',
        '操作',
      ], //摄像头数量
      activeIndex: 0,
      boxStatus: null,
      boxType: 2,
      boxStatusNote: 0,
      statusOption: ['全部', '未分配', '已分配'],
      boxOption: ['全部', '离线', '在线', '异常'],
      typeOption: ['新零售', '红外', '广告机'],
      keyword: '',
      fonturl: httpUrl, //测试环境请求前缀
      currentPage: 1,
      pageSize: 10,
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      // downloadurl: '',
      bindCamera: [],
      vendor: '-1',
      tableData: [],
      total: 0,

      dialogFormVisible: false,
      password: 'password',
      eyeshow: true,
      form: {
        boxNo: '',
        macId: '',
        pkid: '',
        currUser: '',
        currPass: '',
      },
      rules: {
        currUser: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
        currPass: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
      },
      formLabelWidth: '140px',
      dialogAssignVisible: false,
      fileEmpty: false,
      uploadFileName: '',
      uploadUrl: storeHttp + '/api/shop/batchAdd',
      headerData: JSON.parse(localStorage.getItem('headerData')),
      downloadTempUrl: storeHttp + '/api/shop/template',
      dialogResultVisible: false, // 分配结果弹框
      successNum: 0,
      errorList: [],
      warnMsgList: [],
      dialogImportErrVisible: false,
      importErrorReason: '',
      prop: '',
      order: '',
      dataSource: [],
      productType: 2,
      isFetching: false,
    }
  },
  watch: {
    pageOptions: {
      handler() {
        this.formatTableData(this.dataSource)
      },
      deep: true,
    },
    uploadFileName(val) {
      if (val) {
        this.fileEmpty = false
      }
    },
  },
  computed: {
    // 下载链接
    downloadurl() {
      const boxStatus = this.boxStatus == null ? '' : this.boxStatus
      return (
        storeHttp +
        '/api/solution_package/getProductList/download?keyword=' +
        this.keyword +
        '&toshop=' +
        this.activeIndex +
        '&orgcode=' +
        this.orgcode +
        '&productType=' +
        this.productType +
        '&status=' +
        boxStatus
      )
    },
  },
  created() {
    this.copyCheckedData = [...this.checkedData]
    this.activeIndex = this.$route.query.toshop || '1'
  },
  mounted() {
    this.getTreeData()
    this.overviewInit()
  },
  // mounted() {
  //   this.getTreeData()
  //     .then(() => {
  //       //等树组织请求完成再执行
  //       let BoxMng = JSON.parse(sessionStorage.getItem('adBoxMng'))
  //       if (BoxMng === null) {
  //         //缓存
  //       } else {
  //         // this.activeIndex = BoxMng.active
  //         this.orgcode = BoxMng.code !== '' ? BoxMng.code : this.orgcode
  //         this.keyword = BoxMng.keyword
  //         this.checkedName = BoxMng.treeName
  //         this.vendor = BoxMng.vendor
  //         this.boxStatus = BoxMng.status
  //         this.boxType = BoxMng.boxType
  //       }
  //       this.overviewInit()
  //       //this.getCameraVendorList()
  //       this.$refs.tree.setCurrentKey(this.orgcode)
  //     })
  //     .finally(() => {

  //     })
  //   window.onbeforeunload = (e) => {
  //     //缓存
  //     let obj = {
  //       // active: this.activeIndex,
  //       code: this.orgcode,
  //       keyword: this.keyword,
  //       treeName: this.checkedName,
  //       vendor: this.vendor,
  //       status: this.boxStatus,
  //       boxType: this.boxType,
  //     }
  //     sessionStorage.setItem('adBoxMng', JSON.stringify(obj))
  //   }
  // },
  methods: {
    isEmpty,
    formatLabel,
    openDialog(row) {
      this.form.pkid = row.pkid
      this.form.boxNo = row.boxNo
      this.form.macId = row.macId
      this.getAgent()
      this.dialogFormVisible = true
    },
    //设置
    getAgent() {
      //查询用户名
      upgrade
        .getAgent({
          pkid: this.form.pkid,
        })
        .then((res) => {
          if (res && res.status === 0) {
            this.form.currUser = res.data.curruser
          }
        })
    },
    // 显示设置自定义列
    setTrue() {
      const tableColumnKey = this.checkedData.map((item) => item.key)
      this.checkedCities = tableColumnKey
      this.handleCheckedCitiesChange(tableColumnKey)
      this.dialogIsShow = true
    },
    getCheckTrue() {
      //确认按钮
      this.checkedData = this.copyCheckedData.filter((item) => this.checkedCities.includes(item.key))
      this.dialogIsShow = false
    },
    // 恢复默认
    setDefult() {
      this.checkedCities = this.setCheck
      this.handleCheckedCitiesChange(this.checkedCities)
    },
    // 勾选全选按钮
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.copyCheckedData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.copyCheckedData.length
    },
    updateAgentCurrentAuth() {
      // 修改密码
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确认修改密码吗？', '修改密码可能会影响盒子对摄像头的访问', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: 'warning',
          }).then(() => {
            let { pkid, currUser, currPass } = this.form
            let params = { pkid, currUser, currPass }
            updateAgentCurrentAuth(params).then((res) => {
              if (res.data) {
                this.dialogFormVisible = false
                this.$message({
                  message: '成功',
                  type: 'success',
                })
              } else {
                this.$message({
                  message: '失败',
                  type: 'success',
                })
              }
              this.resetForm('ruleForm')
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancelSetpsd() {
      this.dialogFormVisible = false
      this.resetForm('ruleForm')
    },
    handleSelect(key, type) {
      //切换menu
      this[type] = key
      if (type == 'boxStatus') {
        this.boxStatusNote = key
        this[type] = key == '0' ? '' : key - 1
      }
      this.pageOptions.pageNumber = 1
      this.overviewInit()
    },
    async getAgentList() {
      let e = {
        toshop: this.activeIndex,
        status: this.boxStatus,
        orgcode: this.orgcode || '0',
        keyword: this.keyword,
        // vendor: this.vendor,
        // pageindex: this.currentPage,
        // pagesize: this.pageSize,
        productType: 2,
      }
      this.isFetching = true
      return getProductList(e)
        .then((res) => {
          if (res && res.status === 0) {
            this.dataSource = res.data || []
            // this.currentPage = 1
            this.total = res.data?.length || 0
            this.dataSource.forEach((v) => {
              v.toshop = v.toShop == '0' ? '未分配' : v.toShop == '1' ? '已分配' : ''
              v.autobind = v.autobind === 1 ? '是' : '否'
              // v.boxType = this.typeOption[v.boxType]
              // v.boxPort = Number(v.boxPort)
            })
          }
          return this.dataSource
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    // getCameraVendorList () {
    //   window.util.post(getCameraVendorList).then(res => {
    //     if (res && res.status === 0) {
    //       this.bindCamera = res.data;
    //       this.bindCamera.unshift({vendor:'-1',vendorTxt: '全部'})
    //     }
    //   });
    // },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
    },
    async overviewInit() {
      const data = await this.getAgentList()
      if (data) {
        this.formatTableData(data)
        // if (this.keyword.trim() !== '') {
        //   this.searchByKeywords()
        // }
      }
    },
    formatTableData(dataSource) {
      this.tableData = generateTableData(dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
      this.isFetching = false
    },
    async searchByKeywords() {
      await this.overviewInit()
      this.pageOptions.pageNumber = 1
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData(this.dataSource)
    },
    restFirst(row) {
      this.$router.push({
        name: 'adBox-config',
        query: {
          productId: row.productId,
          productType: row.productType,
          cid: row.cid,
          shopId: row.shopId,
          shopName: row.shopName,
          fromRoute: this.$route.name,
        },
      })
    },
    restSecond(row) {
      this.$router.push({
        name: 'adBox-deviceManage',
        query: {
          boxId: row.boxId,
          productId: row.productId,
        },
      })
    },
    handleChange(file) {
      const fileName = file.name
      if (fileName.includes('.xls')) {
        this.uploadFileName = file.name
      } else {
        this.$message.error('只能上传xls文件')
      }
    },
    uploadSuccess(res) {
      if (res.status === 0) {
        this.dialogResultVisible = true
        this.successNum = res.data.successNum
        this.errorList = res.data.errMsgList
        this.warnMsgList = res.data.warnMsgList
      } else {
        this.dialogImportErrVisible = true
        this.importErrorReason = res.msg
        this.$refs.upload.clearFiles()
      }
    },
    uploadError() {
      this.$message.error('文件上传失败')
    },
    submitUpload() {
      if (this.uploadFileName) {
        this.$refs.upload.submit()
        this.dialogAssignVisible = false
      } else {
        this.fileEmpty = true
      }
    },
    // 打开批量分配弹框
    handleAssign() {
      this.dialogAssignVisible = true
    },
    handleUploadResult() {
      this.dialogResultVisible = false
      this.uploadFileName = ''
    },
    handleErrorReason() {
      this.dialogImportErrVisible = false
      this.uploadFileName = ''
    },
    cancelAssign() {
      this.dialogAssignVisible = false
      this.uploadFileName = ''
      this.fileEmpty = false
      this.$refs.upload.clearFiles()
    },
    // 跳转到设备信息页
    async toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      // const accessToken = localStorage.getItem('accessToken')
      const { data: accessToken } = await getNewestToken({})
      window.open(
        href + '?accessToken=' + accessToken + '&boxId=' + row.boxId + '&username=' + username + '&ad=1' + '&type=2'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.operation {
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.el-button.el-button--default.primary {
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
  color: #fff;
}
.menu .el-button + .el-button {
  margin-left: -4px;
}

.titletop {
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.rightnav {
  height: 60px;
  line-height: 60px;
}
.el-button.newbtn {
  width: 60px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  border-radius: 2px;
  font-size: 12px;
}
.batchbtn {
  width: 80px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  background: #f4f5f7;
  border-radius: 2px;
  border-color: #d1d1d1;
  font-size: 12px;
  margin-left: 10px;
}
.el-select .el-input {
  width: 318px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.searchInput {
  width: 350px;
}

/* 按钮 */
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button.is-active,
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
}
.newbtn.el-button.is-plain:focus,
.newbtn.el-button.is-plain:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}
.newbtn.el-button:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button:focus,
.newbtn.el-button:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}

.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
  outline: 0;
}
.batchbtn.el-button.is-active,
.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
}
.batchbtn.el-button.is-plain:focus,
.batchbtn.el-button.is-plain:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}
.batchbtn.el-button:active {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  outline: 0;
}
.batchbtn.el-button:focus,
.batchbtn.el-button:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}

.session1 {
  padding: 20px;
  padding-left: 0;
  padding-top: 0;
  align-items: center;
}

.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

.el-select .el-input,
.el-select .el-input .el-input__inner,
.el-form-item__content .el-input .el-input__inner {
  width: 200px;
}
.el-select-dropdown.el-popper {
  min-width: 200px !important;
}
.addDialog .el-input__prefix,
.addDialog .el-input__suffix {
  top: 6px;
}

/* 导入摄像头 */
.userList div.le1 {
  font-size: 14px;
  color: #333;
  text-align: right;
  font-weight: bold;
  width: 90px;
  text-align: right;
}
.userList .elDiv {
  font-size: 12px;
  color: #333;
  margin-left: 20px;
}
.userList .elDiv > input {
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  width: 210px;
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  margin-right: 10px;
}
.errorShow {
  font-size: 12px;
  color: #ff2600;
  letter-spacing: 0;
  line-height: 12px;
  padding-left: 110px;
}
.alertInfo {
  line-height: 30px;
  background: #f4f5f7;
  border-radius: 4px;
  opacity: 0.85;
  padding: 0 20px;
  color: #999;
}
.userList a {
  font-size: 12px;
  color: #5c76cc;
  cursor: pointer;
}

.importDialog .el-dialog__body {
  padding-bottom: 30px;
}

.loadbtn {
  height: 30px;
  line-height: 30px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}
.el-input-group > .el-input__inner {
  width: 350px;
}

/* 设置自定义列 */
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-checkbox .el-checkbox {
  margin-right: 30px;
}
.ag-custom-column label {
  width: 33.33%;
  margin-bottom: 14px;
}
.ag-custom-column {
  width: 410px;
  position: absolute;
  top: 42px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.bor {
  border: 1px solid #d2d2d2;
}
.fts {
  font-size: 12px;
}
.ag-layout-block {
  position: relative;
  padding: 15px;
}
.ag-layout-block.hori {
  padding-top: 0;
  padding-bottom: 0;
}
label {
  display: inline-block;
  line-height: 1.42857;
  font-style: normal;
}
.ag-model-btn {
  font-family: 'Microsoft Yahei', Helvetica, sans-serif;
  min-width: 63px;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 2px;
}
.ag-model-btn.blue {
  color: #fff;
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.gray {
  color: #333;
  background-color: #f4f5f7;
  border: 1px solid #d2d2d2;
}
.ag-model-btn.gray:active {
  border-color: #d2d2d2;
  background-color: #dddee0;
}
.ag-model-btn.gray:hover {
  border-color: #d2d2d2;
  background-color: #fff;
}
.ag-model-btn.blue:active {
  border-color: #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.blue:hover {
  border-color: #5c76cc;
  background-color: #5c76cc;
}

.addDialog .el-input {
  width: 200px;
}

.el-input-group {
  width: 350px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}

.addDialog .shopBox span {
  top: 12px;
}

.addDialog .treeBox {
  left: 0;
}

.tree-disabled {
  cursor: not-allowed;
  color: #aca899;
}

.required .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

.el-form-item.is-error .shop-name {
  border-color: #f56c6c;
}
.pswbox {
  position: relative;
  width: 200px;
}
.pswbox .el-input__inner {
  padding-right: 23px;
}
.password-icon {
  position: absolute;
  z-index: 100;
  top: 3px;
  right: 5px;
  font-size: 12px;
}
.upload > span {
  font-weight: bold;
  margin: 0 20px;
}
.el-dialog__footer {
  padding: 20px;
}
.assignDialog-footer > span {
  color: #5c76cc;
  cursor: pointer;
}
</style>